import {ModalType} from "../../enums/global/modal-type.enum";
import {IconName} from "../../enums/global/icon-name.enum";

export class InfoCardConfigModel {
  title: InfoCardTextConfig;
  description: InfoCardTextConfig;
  type: ModalType;
  iconName: IconName;
  width: string;


  constructor(
    type: ModalType,
    title: InfoCardTextConfig,
    description: InfoCardTextConfig,
    width?: string,
    iconName?: IconName
  ) {
    this.title = title;
    this.description = description;
    this.type = type;
    this.iconName = iconName;
    this.width = width;
  }
}

export class InfoCardTextConfig {
  text: string;
  fontSize: string;

  constructor(text: string, fontSize: string) {
    this.text = text;
    this.fontSize = fontSize;
  }
}
