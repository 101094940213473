import {Injectable} from "@angular/core";
import {ModalType} from "../../enums/global/modal-type.enum";
import {IconName} from "../../enums/global/icon-name.enum";
import {InfoCardConfigModel, InfoCardTextConfig} from "../../models/global/info-card-config.model";
import {Person} from "../../models/person.model";
import {AvatarUser} from "../../components/avatar-component/avatar-user.model";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
/**
 * The UtilsService provides utility methods that are used globally throughout the application.
 */
export class UtilsService {

  /**
   * Returns the CSS class for the modal icon based on the given model type.
   * @param modalType modal type {@link ModalType}
   */
  cssClass(modalType: ModalType) {
    switch (modalType){
      case ModalType.SUCCESS:
        return 'featured-icon-primary-xl';
      case ModalType.ERROR:
        return 'featured-icon-error-xl';
      case ModalType.WARNING:
        return 'featured-icon-warning-xl';
      case ModalType.SECONDARY:
        return 'featured-icon-gray-xl';
      default:
        return 'featured-icon-primary-xl';
    }
  }

  /**
   * Returns the CSS class for the confirmation button based on the given model type.
   * @param modalType modal type {@link ModalType}
   */
  confirmButtonClass(modalType: ModalType) {
    switch (modalType){
      case ModalType.SUCCESS:
        return 'btn-primary';
      case ModalType.ERROR:
        return 'btn-danger';
      case ModalType.WARNING:
        return 'btn-primary';
      default:
        return 'btn-primary';
    }
  }

  /**
   * Returns icon name to use in the modal on the given model type.
   * @param modalType modal type {@link ModalType}
   */
  modalIconName(modalType: ModalType) {
    switch (modalType){
      case ModalType.SUCCESS:
        return IconName.CHECKED_CIRCLE;
      case ModalType.ERROR:
        return IconName.ALERT_CIRCLE;
      case ModalType.WARNING:
        return IconName.ALERT_TRIANGLE;
      default:
        return IconName.CHECKED_CIRCLE;
    }
  }

  /**
   * Builds and returns the config of awaiting GDPR preferences card info.
   * Displayed when a contact has an ongoing GDPR request or no requests at all.
   */
  getWaitingGDPRRequestCardConfig() {
    return new InfoCardConfigModel(
      ModalType.SECONDARY,
      new InfoCardTextConfig(
        'Awaiting GDPR Preferences',
        '20px'
      ),
      new InfoCardTextConfig(
        `We're waiting to receive this contact's GDPR preferences.
       <br>Once the contact submits their preferences, the information will be updated here.`,
        '12px'
      ),
      '400px',
      IconName.GDPR
    );
  }

  /**
   * Builds and returns the config of No GDPR Preferences card info.
   * Displayed when a contact has an ongoing GDPR request or no requests at all.
   */
  getGDPRRequestCardConfig() {
    return new InfoCardConfigModel(
      ModalType.SECONDARY,
      new InfoCardTextConfig(
        'No GDPR Preferences',
        '20px'
      ),
      new InfoCardTextConfig(
        `No GDPR consent request has been sent yet.
       <br>Once a request is made and preferences are submitted by the contact,the information will be updated here.`,
        '12px'
      ),
      '400px',
      IconName.GDPR
    );
  }

  createAvatarUsers(persons: Person[], check: boolean = false): AvatarUser[] {
    return persons.map((person: Person) => new AvatarUser(person, person.role, check));
  }

  /**
   * This method checks if a form control identified by the specified name
   * within the specified form group has errors of the specified validation type.
   * @param formGroup       form group {@link FormGroup}
   * @param controlName     form control name (ex: firstName,...)
   * @param validationType  validation applied to form control (ex: required,...)
   */
  isControlHasError(formGroup: FormGroup, controlName: string, validationType: string): boolean {
    let control = formGroup.get(controlName);
    if (!control) {
      return false;
    }
    return control.hasError(validationType) && (control.dirty || control.touched);
  }
}
